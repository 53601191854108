<template>
  <el-dialog
    title="奖励学生"
    :visible.sync="dialogVisible"
    center
    width="1030px"
    :before-close="handleClose"
    :destroy-on-close="true"
  >
    <div class="props-cnt">
      <label class="props-title">奖励物品：</label>
      <div class="flex1 props-list">
        <!-- <el-carousel :interval="5000" arrow="always" indicator-position="none" :autoplay="false" height="110px"
       @prev="prev" @next="next">
        <el-carousel-item class="prop-list">
          <el-image
            v-for="item in propList" :key="item.id" 
            class="prop-item prop-img"
            :class="item.id == currentProp.id?'active-prop':''"
            :src="item.image"
            fit="fit"
            @click="chooseProp(item)">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
        </el-carousel-item>
      </el-carousel> -->
        <span
          class="btn-prev"
          @click="prev"
        ><i class="el-icon-arrow-left"></i></span>
        <div class="prop-list">
          <el-image
            v-for="item in propList"
            :key="item.id"
            class="prop-item prop-img"
            :class="item.id == currentProp.id?'active-prop':''"
            :src="item.image"
            fit="fit"
            @click="chooseProp(item)"
          >
            <div
              slot="error"
              class="image-slot"
            >
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
        <span
          class="btn-next"
          @click="next"
        ><i class="el-icon-arrow-right"></i></span>
      </div>
      <div class="current-prop">
        <el-image
          style="width: 80px; height: 80px"
          :src="currentProp.image"
          fit="fit"
        >
          <div
            slot="error"
            class="image-slot"
          >
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <div>
          <span>{{currentProp.name}}</span>
          <el-divider direction="vertical"></el-divider>
          <span>{{currentProp.integral}} 积分</span>
        </div>
      </div>
    </div>

    <div class="props-cnt">
      <label class="props-title">奖励评语：</label>
      <el-input
        class="flex1"
        maxlength="30"
        v-model="form.comment"
        placeholder="请输入奖励评语"
      ></el-input>
    </div>

    <div class="props-cnt">
      <label class="props-title">奖励学生：</label>
      <div class="student-list flex1">
        <div class="student-search">
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllStudent"
          >全选</el-checkbox>
          <el-input
            class="search-input"
            placeholder="请输入内容"
            suffix-icon="el-icon-search"
            v-model="studentName"
            size="small"
          >
          </el-input>
        </div>
        <el-divider></el-divider>
        <div class="students">
          <el-checkbox-group
            v-model="checkStudents"
            @change="handleStudentChange"
          >
            <el-checkbox
              v-for="item in students"
              :label="item.studentId"
              :key="item.studentId"
            >{{item.studentName}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
    </div>

    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="handleClose">取 消</el-button>
      <el-button
        type="primary"
        @click="sendProp"
        :disabled="btnDisabled"
        :loading="btnLoading"
      >确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getProps, getStudents, sendPropApi } from '@/api/course';
import { getUserInfo } from '@/utils/auth';

export default {
  name: 'Props',
  props: {
    dialogVisible: Boolean,
  },
  data() {
    return {
      btnDisabled: false,
      btnLoading: false,
      checkAll: false,
      studentName: '',
      form: {
        comment: ''
      },
      students: [],
      checkStudents: [],
      isIndeterminate: true,
      propList: [],
      queryParam: {
        pageNo: 1,
        pageSize: 20,
        total: 0
        //isUse: true
      },
      currentProp: {}
    };
  },
  watch: {
    studentName(val) {
      this.initStudents()
    }
  },
  computed: {
    userInfo: function () {
      return getUserInfo();
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.initStudents()
      this.initPropList()

      // this.students = [
      //   {id: 1, name: "章三"},{id: 2, name: "张三"},{id: 3, name: "里斯"},{id: 4, name: "李四"},{id: 5, name: "王武"},
      //   {id: 6, name: "章三"},{id: 8, name: "张三"},{id: 10, name: "里斯"},{id: 12, name: "李四"},{id: 14, name: "王武"},
      //   {id: 7, name: "章三"},{id: 9, name: "张三"},{id: 11, name: "里斯"},{id: 13, name: "李四"},{id: 15, name: "王武"},
      //   {id: 1, name: "章三"},{id: 2, name: "张三"},{id: 3, name: "里斯"},{id: 4, name: "李四"},{id: 5, name: "王武"},
      //   {id: 6, name: "章三"},{id: 8, name: "张三"},{id: 10, name: "里斯"},{id: 12, name: "李四"},{id: 14, name: "王武"},
      //   {id: 7, name: "章三"},{id: 9, name: "张三"},{id: 11, name: "里斯"},{id: 13, name: "李四"},{id: 15, name: "哇哈哈"},
      //   {id: 1, name: "章三"},{id: 2, name: "张三"},{id: 3, name: "里斯"},{id: 4, name: "李四"},{id: 5, name: "王武"},
      //   {id: 6, name: "章三"},{id: 8, name: "张三"},{id: 10, name: "里斯"},{id: 12, name: "李四"},{id: 14, name: "王武"},
      //   {id: 7, name: "章三"},{id: 9, name: "张三"},{id: 11, name: "里斯"},{id: 13, name: "李四"},{id: 15, name: "王武"},
      //   {id: 1, name: "章三"},{id: 2, name: "张三"},{id: 3, name: "里斯"},{id: 4, name: "李四"},{id: 5, name: "王武"},
      //   {id: 6, name: "章三"},{id: 8, name: "张三"},{id: 10, name: "里斯"},{id: 12, name: "李四"},{id: 14, name: "王武"},
      //   {id: 7, name: "章三"},{id: 9, name: "张三"},{id: 11, name: "里斯"},{id: 13, name: "李四"},{id: 15, name: "哇哈哈"}
      // ]
    },
    initStudents() {
      if(!this.$route.query.classInfoId) return
      let param = {}
      param.classId = this.$route.query.classInfoId
      param.name = this.studentName
      getStudents(param).then(res => {
        if (res.success) {
          this.students = res.result
        }
      })
    },
    initPropList() {
      getProps(this.queryParam).then(res => {
        if (res.success) {
          //this.queryParam.pageNo = res.result.current
          this.queryParam.total = res.result.total
          this.propList = res.result.records
          if (!this.currentProp.id && this.propList && this.propList.length > 0) this.currentProp = this.propList[0]
        }
      })
    },
    prev() {
      if (this.queryParam.pageNo == 1) return;
      this.queryParam.pageNo--
      this.initPropList()
    },
    next() {
      if (this.propList.length == this.queryParam.pageSize && (this.queryParam.total % this.queryParam.pageSize) > 0) {
        this.queryParam.pageNo++
        this.initPropList()
      }
    },
    handleClose() {
      this.btnLoading = false;
      this.$emit('update:dialogVisible', false);
    },
    chooseProp(prop) {
      this.currentProp = prop
    },
    handleStudentChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.students.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.students.length;
    },
    handleCheckAllStudent(val) {
      this.checkStudents = []
      if (val) {
        this.students.map(item => {
          this.checkStudents.push(item.studentId)
        })
      }
      this.isIndeterminate = false;
    },
    sendProp() {
      if (!this.currentProp || !this.currentProp.id) return this.$message.warning("请选择要发放的道具")
      //if(!this.comment) return this.$message.warning("请选择要发放的道具")
      if (!this.checkStudents || this.checkStudents.length == 0) return this.$message.warning("请选择要发放的道具的学生")

      this.form.propId = this.currentProp.id
      this.form.integral = this.currentProp.integral
      this.form.students = this.checkStudents
      this.form.courseUnitId = this.$route.query.unitId
      this.form.classPlanId = this.$route.query.classPlanId
      this.form.teachId = this.userInfo.id
      sendPropApi(this.form).then(res => {
        if (res.success) {
          this.$message.success("道具发放成功！");
          this.handleClose()
        }
        else this.$message.error(res.message)
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.props-cnt {
  display: flex;
  margin: 0px 15px 30px 15px;
}
.flex1 {
  flex: 1;
}
.props-title {
  font-size: 16px;
  width: 80px;
}
.current-prop {
  width: 160px;
  text-align: center;
}

.props-list {
  position: relative;
  height: 110px;
  //background: #e3e0e0;
  //border-radius: 5px;
  //border: 1px solid #cdcdcd;
}
.prop-img {
  width: 40px;
  height: 40px;
  margin: 2px 10px 8px 10px;
  cursor: pointer;
}
.active-prop {
  // border: 1px solid blue;
  border: 1px solid #cdcdcd;
}
.student-list {
}
.prop-list {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  align-items: center;
  padding: 6px 55px;

  .prop-item {
  }
}

.students {
  display: flex;
  flex-wrap: wrap;
  max-height: 200px;
  overflow-y: auto;
}
.student-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-input {
  width: 250px;
}
.btn-prev,
.btn-next {
  position: absolute;
  top: 34px;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 18px;
  background: rgb(0 0 0 / 10%);
  color: #ffffff;
  cursor: pointer;
  transition: all linear 0.2s;
}
.btn-next {
  right: 10px;
}
.btn-prev {
  left: 10px;
}
.btn-prev:hover,
.btn-next:hover {
  background: rgb(0 0 0 / 20%);
}
</style>
<style>
.search-input .el-input__inner {
  border-radius: 30px;
}
.student-search .el-divider--horizontal {
  margin: 10px 0 !important;
}

.students::-webkit-scrollbar {
  -webkit-appearance: none;
}
.students::-webkit-scrollbar:vertical {
  /* 设置垂直滚动条宽度 */
  width: 8px;
}

/* 这里不需要用到这个 */
.students::-webkit-scrollbar:horizontal {
  /* 设置水平滚动条厚度 */
  height: 80px;
}

.students::-webkit-scrollbar-thumb {
  /* 滚动条的其他样式定制，注意，这个一定也要定制，否则就是一个透明的滚动条 */
  border-radius: 8px;
  border: 2px solid rgba(255, 255, 255, 0.4);
  background-color: rgba(0, 0, 0, 0.5);
}
</style>

<template>
  <el-dialog
    title="奖励学生"
    :visible.sync="dialogVisible"
    center
    width="1030px"
    :before-close="handleClose"
  >
    <el-tabs
      v-model="tabActive"
      type="card"
      @tab-click="changeTab"
    >
      <el-tab-pane
        label="本堂奖励"
        name="first"
      >
        <el-table :data="currentClassList">
          <el-table-column
            align="center"
            fixed
            property="teachName"
            label="老师"
          ></el-table-column>
          <el-table-column
            align="center"
            property="unitName"
            label="课程"
          ></el-table-column>
          <el-table-column
            align="center"
            property="propName"
            label="奖励"
          ></el-table-column>
          <el-table-column
            align="center"
            property="comment"
            label="评语"
          ></el-table-column>
          <el-table-column
            align="center"
            property="integral"
            label="积分"
          ></el-table-column>
          <el-table-column
            align="center"
            property="createTime"
            label="时间"
          ></el-table-column>
        </el-table>
        <div class="content-pagination">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="queryParam.pageNo"
            :page-size="queryParam.pageSize"
            layout="total, prev, pager, next,jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="历史奖励"
        name="second"
      >
        <el-table :data="historyList">
          <el-table-column
            fixed
            align="center"
            property="teachName"
            label="老师"
          ></el-table-column>
          <el-table-column
            align="center"
            property="unitName"
            label="课程"
          ></el-table-column>
          <el-table-column
            align="center"
            property="propName"
            label="奖励"
          ></el-table-column>
          <el-table-column
            align="center"
            property="comment"
            label="评语"
          ></el-table-column>
          <el-table-column
            align="center"
            property="integral"
            label="积分"
          ></el-table-column>
          <el-table-column
            align="center"
            property="createTime"
            label="时间"
          ></el-table-column>
        </el-table>
        <div class="content-pagination">
          <el-pagination
            background
            @current-change="handleCurrentChange2"
            :current-page="queryParam2.pageNo"
            :page-size="queryParam2.pageSize"
            layout="total, prev, pager, next,jumper"
            :total="total2"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="handleClose">取 消</el-button>
      <el-button
        type="primary"
        @click="handleClose"
      >确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getMyProps } from '@/api/course';
import { getUserInfo } from '@/utils/auth';

export default {
  name: 'Awards',
  props: {
    dialogVisible: Boolean,
  },
  data() {
    return {
      btnDisabled: false,
      btnLoading: false,
      tabActive: 'first',
      currentClassList: [],
      historyList: [],
      queryParam: {
        classPlanId: this.$route.query.classPlanId,
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      queryParam2: {
        pageNo: 1,
        pageSize: 10
      },
      total2: 0
    };
  },
  computed: {
    userInfo: function () {
      return getUserInfo();
    },
  },
  created() {
    //this.initMyProps(this.queryParam)
    this.changeTab("first")
  },
  methods: {
    initMyProps(queryParam) {
      queryParam.studentId = this.userInfo.id
      getMyProps(queryParam).then(res => {
        if (res.success) {
          if (this.tabActive == "first") {
            this.currentClassList = res.result.records
            this.total = res.result.total
          }
          else {
            this.historyList = res.result.records
            this.total2 = res.result.total
          }
        }
      })
    },
    handleClose() {
      this.$emit('update:dialogVisible', false);
    },
    changeTab(val, event) {
      this.initMyProps(this.tabActive == "first" ? this.queryParam : this.queryParam2)
    },
    handleCurrentChange(val) {
      this.queryParam.pageNo = val;
      this.initMyProps(this.queryParam);
    },
    handleCurrentChange2(val) {
      this.queryParam2.pageNo = val;
      this.initMyProps(this.queryParam2);
    },
  },
};
</script>

<style scoped>
.content-pagination {
  margin: 40px 10px 10px 10px;
  text-align: center;
}
</style>

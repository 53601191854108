<template>
  <div class="teaching-container">
    <div class="container-head">
      <!-- <i :class="stepIndex === 1 ? 'el-icon-arrow-left icon-btn-disabled' : 'el-icon-arrow-left icon-btn-active'" @click="handlePrevStepClick"></i>
      <div class="head-nav">
        <span class="head-class-name">{{ unitName }}</span>
        <div
          @click="handleStepClick(count)"
          v-for="count in totalStep"
          :key="count"
          :class="count === stepIndex ? 'step step-active' : 'step'"
        >
          {{ count }}
        </div>
      </div>
      <i :class="stepIndex === totalStep ? 'el-icon-arrow-right icon-btn-disabled' : 'el-icon-arrow-right icon-btn-active'" @click="handleNextStepClick"></i> -->
      <i :class="stepIndex === 1 ? 'el-icon-arrow-left icon-btn-disabled' : 'el-icon-arrow-left icon-btn-active'" @click="handlePrevStepClick"></i>    
       <el-pagination
       ref="cuPagination"
       class="cu-pagination"
        @current-change="handleStepClick"
        layout="slot, pager"
        :total="totalStep*10">
        <span class="head-class-name">{{ unitName }}</span>
     </el-pagination>
     <i :class="stepIndex === totalStep ? 'el-icon-arrow-right icon-btn-disabled' : 'el-icon-arrow-right icon-btn-active'" @click="handleNextStepClick"></i>
      
      <!-- <el-button
        @click="handleNextStepClick"
        :disabled="stepIndex === totalStep"
        round
      >下一步</el-button> -->
      <template v-if="classPlanId != null">
        <el-button
          v-if="!userInfo.role || userInfo.role.includes($role.SCHOOLADMIN) 
      || userInfo.role.includes($role.TEACHER) 
      || userInfo.role.includes($role.EXAMADMIN)"
          @click="chooseProps"
          round
        >课堂互动</el-button>
        <el-button
          v-else
          @click="showMyAwards"
          round
        >我的奖励</el-button>
      </template>
    </div>
    <div
      class="container-content"
      :class="collapse ? '' : 'container-content-without-sider'"
    >
      <div class="content-sider">
        <!-- <div> -->
        <div
          class="sider-head"
          title=""
        >{{ unitName }}</div>
        <div class="sider-content">
          <el-tabs
            v-model="activeTab"
            @tab-click="handleTabClick"
          >
            <el-tab-pane
              label="01 课程引导"
              name="guide"
            >
              <!-- <TeachingGuideCard v-for="(data,index) in guideList" :key="index" :data="data" /> -->
              <TeachingGuideCard
                v-if="guideList.length"
                :data="guideList[stepIndex - 1]"
              />
            </el-tab-pane>
            <el-tab-pane
              label="02 课程资料"
              name="data"
            >
              <TeachingDataCard
                v-for="(data, index) in resourceList"
                :key="index"
                :data="data"
              />
              <Empty
                v-show="resourceList.length === 0"
                description="暂无课程资料"
              />
            </el-tab-pane>
            <!-- <el-tab-pane
              label="03 课后练习"
              name="practice"
            >
              <PracticeQuestionList
                :unitId="unitId"
                :hasGetData="hasGetData.practice"
              />
            </el-tab-pane> -->
          </el-tabs>
        </div>
        <!-- </div> -->
        <div
          class="collapse-change"
          @click="handleCollapseChage"
        >
          <div :class="collapse ? 'icon-close' : 'icon-open'" />
        </div>
      </div>
      <div class="content-main">
        <iframe
          :src="iframeSrc"
          style="width: 100%; height: 100%; background: #fff"
          id="coding"
        />
      </div>
      <div
        v-viewer="imgViewOptions"
        class="images clearfix"
        style="display: none"
      >
        <img
          :src="imgSrc"
          :data-source="imgSrc"
          class="image"
        />
      </div>
    </div>

    <Props :dialogVisible.sync="propDialogVisible"></Props>
    <Awards :dialogVisible.sync="awardsDialogVisible"></Awards>

    <el-dialog
      :visible="showProp"
      width="400px"
      :modal="true"
      @close="showProp = false"
      class="prop-dialog"
    >
      <span class="count-down">{{time}}</span>
      <el-image
        :class="propClass"
        :src="myProp.propImage"
        fit="fit"
      >
        <div
          slot="error"
          class="image-slot"
        >
          <i class="el-icon-picture-outline"></i>
        </div>
      </el-image>

      <p class="evalute-msg">{{myProp.comment}}</p>
    </el-dialog>
  </div>
</template>

<script>
import { getUserInfo, hasRole } from '@/utils/auth';
import { getCourseGuide, getCourseResource, updateStudyTime, getPropsBySudent } from '@/api/course';
import TeachingGuideCard from '@/components/teaching/TeachingGuideCard.vue';
import TeachingDataCard from '@/components/teaching/TeachingDataCard.vue';
import PracticeQuestionList from '@/components/teaching/PracticeQuestionList.vue';
import Props from '@/components/teaching/Props.vue';
import Awards from '@/components/teaching/Awards.vue';
import Empty from '@/components/modules/Empty.vue';
import Vue from 'vue';
import { ACCESS_TOKEN } from '@/store/mutation-types';
import { Message } from 'element-ui';
export default {
  data() {
    return {
      classPlanId: null,
      propClass: 'showProp',
      time: 3,
      propTimer: null,
      showProp: false,
      myProp: {},
      propDialogVisible: false,
      awardsDialogVisible: false,
      awardDialogVisible: false,
      activeTab: 'guide',
      iframeSrc: '',
      unitId: '',
      unitName: '',
      collapse: true,
      totalStep: 0,
      stepIndex: 1,
      guideList: [],
      imgSrc: 'http://localhost:8080/img/match-bg.81a37cf9.png',
      imgViewOptions: {
        toolbar: true,
        url: 'data-source',
      },
      resourceList: [],
      timer: null,
      hasGetData: { data: false, practice: false } //是否请求数据
    };
  },
  components: {
    TeachingGuideCard,
    TeachingDataCard,
    PracticeQuestionList,
    Empty,
    Props,
    Awards
  },
  created: function () {
    const token = Vue.ls.get(ACCESS_TOKEN);
    if (!token) {
      location.href = `/login?redirect_uri=${encodeURIComponent(location.href)}`;
      // window.location.href = window.location.origin+"/passport/dologin?url="+encodeURI(location.href)
      return;
    }
    const lab = this.$route.query.lab;
    const id = this.$route.query.unitId;
    const unitName = this.$route.query.unitName;
    this.classPlanId = this.$route.query.classPlanId;
    //if(userInfo.role && userInfo.role.includes(this.$role.STUDENT)) 
    this.timer = setInterval(this.updateStudyTime, 10000)

    let route = ``;
    if (lab === 'scratch3') {
      route = `/${lab}?unitId=${id}`;
    } else {
      route = `/coding?lab=${lab}?unitId=${id}`;
    }
    this.iframeSrc =
      window.location.host.indexOf('localhost') === -1
        ? `${window.location.protocol}//${window.location.host}${route}`
        : `https://www.eqibian.com${route}`;
    this.unitId = id;
    this.unitName = unitName;
    const params = { courseUnitId: id };
    getCourseGuide(params)
      .then((res) => {
        //获取失败
        if (!res.success) {
          if (res.code == -1) {
            this.$alert('您还没有课程，请联系学校管理员或平台为您添加课程team@eqibian.com', '', {
              showConfirmButton: false,
              showClose: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
              closeOnHashChange: false,
              center: true
            });
            return;
          } else if (res.code == -2) {
            this.$alert('该课程不在排课记录中!', '', {
              showConfirmButton: false,
              showClose: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
              closeOnHashChange: false,
              center: true
            });
            return
          } else {
            this.$message.error('' || "获取课程数据失败");
            return;
          }
        }
        const { result = [] } = res;
        this.guideList = result;
        this.totalStep = (result && result.length) || 0;
      })
      .catch((err) => { });
  },
  mounted: function () {
  },
  beforeDestroy() {
    clearInterval(this.timer)
    clearInterval(this.propTimer)
  },
  updated: function () {
    const imgEles = document.querySelectorAll('.teaching-guide-card .text img');
    for (let index = 0; index < imgEles.length; index++) {
      const element = imgEles[index];
      element.style.cursor = 'pointer';
      element.style.width = 'auto';
      element.style.height = 'auto';
      element.style.maxWidth = '100%';
      element.style.maxHeight = '100%';
      element.title = '点击放大查看';
      element.addEventListener('click', (e) => {
        this.imgSrc = e.target.src;
        this.show();
      });
    }
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
    userInfo: function () {
      return getUserInfo();
    },
  },
  methods: {
    initMyProp() { },
    chooseProps() {
      this.propDialogVisible = true
    },
    showMyAward() {
      getPropsBySudent({ studentId: this.userInfo.id, classId: this.classPlanId }).then((res) => {
        let p = res.result
        let oldp = JSON.parse(localStorage.getItem("currentProp"))

        if (p && ((oldp && p.id != oldp.id) || !oldp)) {
          localStorage.setItem("currentProp", JSON.stringify(p))
          this.myProp = p
          this.showProp = true
          this.time = 3
          let _this = this
          this.propTimer = setInterval(function () {
            if (_this.time == 1) _this.closeProp()
            else _this.time--
          }, 1000)
        }
      });
    },
    showMyAwards() {
      this.awardsDialogVisible = true
    },
    closeProp() {
      this.showProp = false
      clearInterval(this.propTimer)
    },
    updateStudyTime() {
      updateStudyTime({ courseUnitId: this.$route.query.unitId, studentId: this.userInfo.id, classPlanId: this.classPlanId }).then((res) => { console.log('updating time...') });
      this.showMyAward()
    },
    addClassWithImg(htmlContent) {
      let r = RegExp(/<[img]+\s+(.*?)(?<id>\w*?)[\s'"](.*?)>/g);
      let matchres = htmlContent.match(r); //返回数组['<img ... />','<img ... />']
      if (matchres) {
        matchres.forEach((item, index) => {
          let len = item.length;
          let _str =
            item.slice(0, len - 2) +
            ' style="width:60%;height:200px;text-align:center;margin:0 20%;"/>'; //追加之后的img
          htmlContent = htmlContent.replace(item, _str);
        });
      }
      const resultHtml = parseHtml(htmlContent);
      return resultHtml;
    },
    show() {
      const viewer = this.$el.querySelector('.images').$viewer;
      viewer.show();
    },
    onImgClick(e) { },
    handleNextStepClick() {
      if(this.stepIndex == this.totalStep) return
      this.$refs.cuPagination.next()
    },
    handlePrevStepClick() {
      if(this.stepIndex==1) return
      this.$refs.cuPagination.prev()
      //this.handleStepClick(this.stepIndex - 1);
    },
    handleStepClick(step) {
      this.activeTab = 'guide';
      this.stepIndex = step;
      const scrollEle = document.getElementsByClassName('el-tabs__content')[0];
      const guideList = document.getElementById('pane-guide').children;
      scrollEle.scrollTop = 0; //guideList[this.stepIndex - 1].offsetTop;
    },
    handleTabClick(tab, event) {
      if (tab.name === 'data' && !this.hasGetData['data']) {
        getCourseResource({ courseUnitId: this.unitId }).then((res) => {
          //获取失败
          if (!res.success) {
            this.$message.error(res.message || "获取课程数据失败");
            return;
          }
          const result = res.result || [];
          this.resourceList = result;
          this.hasGetData['data'] = true;
        });
      } else if (tab.name === 'practice' && !this.hasGetData['practice']) {
        this.hasGetData['practice'] = true;
      }
    },
    handleCollapseChage() {
      this.collapse = !this.collapse;
    },
    handleSubmitClick() { },
  },
};
</script>
<style scoped>
.sider-content /deep/.el-tabs__nav {
  width: 100% !important;
}
.sider-content /deep/.el-tabs__item {
  padding: 0 12px;
  font-weight: bold;
  width: 50% !important;
  text-align: center;
}
.sider-content /deep/ .el-tabs__item:hover {
  color: #409eff;
}
.sider-content /deep/ .el-tabs__item.is-active {
  color: #409eff;
}
.sider-content /deep/ .el-tabs__active-bar {
  background-color: #409eff;
  height: 4px;
}
.sider-content /deep/ .el-tabs {
  height: 100%;
}
/* .sider-content /deep/ .el-tabs{
        overflow: auto;
        height: calc(100% - 57px);
    } */

@keyframes showPropAnimate {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes hidePropAnimate {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
.showProp {
  -webkit-animation-name: showPropAnimate; /*关键帧名称*/
  -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
  -webkit-animation-duration: 1s; /*动画所花费的时间*/
}
.hideProp {
  -webkit-animation-name: hidePropAnimate; /*关键帧名称*/
  -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
  -webkit-animation-duration: 1s; /*动画所花费的时间*/
}
.prop-dialog /deep/ .el-dialog {
  background: transparent !important;
  box-shadow: 0 0 0 rgb(0 0 0 / 10%);
}
.prop-dialog /deep/ .el-dialog__headerbtn .el-dialog__close {
  color: #ffffff;
}
.prop-dialog {
  text-align: center;
}
.evalute-msg {
  font-size: 18px;
  color: #ffffff;
}
.count-down {
  color: #ffffff;
  position: absolute;
  right: 30px;
  font-size: 20px;
}
.icon-btn-active{
  color:#ffffff; font-size: 32px; font-weight: 500; cursor: pointer;
}
.icon-btn-disabled{
  color:#aeaeae; font-size: 32px; font-weight: 500; cursor: not-allowed;
}
.cu-pagination{
  background: #ffffff;
  height: 40px;
  line-height: 40px;
  border-radius: 40px;
  padding: 5px 20px;
  color: #0081ef;
  font-size: 18px;
  font-family: Arial Regular,Arial,sans-serif;
}

.cu-pagination .head-class-name{
    padding-right: 20px;
    font-size: 18px;
    font-family: Arial Regular,Arial,sans-serif;
    font-weight: 400;
}

/deep/ .cu-pagination .el-pager li{
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 30px;
  font-size: 18px;
  font-family: Arial Regular,Arial,sans-serif;
  margin-right: 5px;
  font-weight: 500;
}
/deep/ .cu-pagination .el-pager li.active{
  color: #ffffff;
  background: #0081ef;
}
</style>
<style lang="scss" scoped>
@import '@/assets/css/views/teaching.scss';
</style>
